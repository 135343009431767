$google-maps-width: 98% ;
$google-maps-height: 19rem ;
$google-maps-height--large: 25rem;

.google-maps{
    width: $google-maps-width;
    height: $google-maps-height;
    margin: 0 auto 1rem;
}

.google-maps--large{
    height: $google-maps-height--large;
}
