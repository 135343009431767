/*--------------------------------------------------*/
/* Back to Top */
/*--------------------------------------------------*/

.back-top a {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999999999;

    color: #eee;
    background-color: rgb(5, 5, 5);
    background-color: rgba(0, 0, 0, 0.3);

    transition: all .25s ease;

    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.back-top a:hover {
    background-color: rgb(5, 5, 5);
    background-color: rgba(0, 0, 0, 0.7);
}

.back-top a i {
    font-size: 32px;
}

@include media-breakpoint-down(md)  {
    .back-top a {
        position: fixed;
        bottom: 40px;
        right: 10px;
    }

    .back-top a i {
        font-size: 1.6em;
    }
}
