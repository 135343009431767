@charset "UTF-8";
/*! extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:34:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-29 10:30:39
*/
/* setup
* @Author: Jeff Shields
* @Date:   2019-01-20 06:48:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-02-17 09:50:18
*/
/* bs4 required
* @Author: Jeff Shields
* @Date:   2019-01-20 06:51:37
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-27 11:23:38
*/
/* load extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:22:35
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-02-18 10:31:07
*/
/*
    Custom styles

*/
@media (min-width: 1200px) {
  html {
    font-size: 1.25rem;
  }
  .navbar * {
    font-size: 0.8rem;
  }
}

.fixed-top .dropdown-menu {
  max-height: 70vh;
  overflow-y: auto;
}

.rhythum {
  margin-bottom: 1.5rem !important;
}

.picture-frame {
  border: 5px ridge #ffc107;
}

@media (min-width: 992px) {
  .content,
  .footer {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.img-info1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-info1:before,
.img-info:after {
  content: '';
}

/*--------------------------------------------------*/
/* Back to Top */
/*--------------------------------------------------*/
.back-top a {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999999;
  color: #eee;
  background-color: #050505;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .25s ease;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.back-top a:hover {
  background-color: #050505;
  background-color: rgba(0, 0, 0, 0.7);
}

.back-top a i {
  font-size: 32px;
}

@media (max-width: 991.98px) {
  .back-top a {
    position: fixed;
    bottom: 40px;
    right: 10px;
  }
  .back-top a i {
    font-size: 1.6em;
  }
}

.audio {
  width: 75%;
  transition: all 0.5s linear;
  box-shadow: 2px 2px 4px 0px #006773;
  border-radius: 7px 7px 7px 7px;
}

.logo {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

@media (min-width: 992px) {
  .logo {
    margin-right: auto;
    margin-left: 0;
  }
}

.blockquote-quotemark {
  padding-left: 2em;
  border-left: 0 solid #007bff;
}

.blockquote-quotemark:before {
  display: block;
  height: 0;
  content: "“";
  margin-left: -.5em;
  font: italic 4em/.75 Cochin, Georgia, "Times New Roman", serif;
  color: #17a2b8;
}

.blockquote-color {
  border-color: #007bff;
}

.blockquote-color-bg {
  border-color: #6c757d;
  border-bottom: solid 1px #007bff;
  background-color: #6c757d;
}

.blockquote-big {
  font-size: 1.8em;
  line-height: 140%;
  font-style: oblique;
  border: none;
}

.blockquote-big footer {
  font-style: normal;
  font-size: .7em;
}

.blockquote-color-bg-primary {
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  border: none;
}

.blockquote-color-bg-primary:after,
.blockquote-color-bg-dark:after {
  font-family: "FontAwesome";
  content: "\f10e";
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #fff;
  font-size: 2.6em;
}

.blockquote-color-bg-dark {
  font-size: 1em;
  background-color: #444;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  border: none;
}

.blockquote-color-bg-light {
  background-color: #ddd;
  border: none;
  color: #333;
  font-size: 1em;
  padding: 20px;
  position: relative;
  text-align: center;
  text-shadow: 1px 1px 0 white;
  text-shadow: 1px 1px 0 white;
}

.blockquote-color-bg-light:after {
  font-family: "FontAwesome";
  content: "\f10e";
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #333;
  font-size: 2.6em;
}

.blockquote-color-bg-primary footer,
.blockquote-color-bg-dark footer {
  color: #eee;
  font-size: .9em;
}

.blockquote-color-bg-light footer {
  color: #666;
  font-size: .9em;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 60px 1em 0.2em;
  text-align: center;
}

.day-of-week {
  width: 14.285714%;
}

/*
* @Author: Jeff Shields
* @Date:   2019-01-16 18:44:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-06 09:24:30
*/
@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    column-count: 4;
  }
}

@media (min-width: 0) {
  .card-deck .card {
    flex: 0 0 calc(100% - 30px);
  }
}

@media (min-width: 576px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px);
  }
}

@media (min-width: 768px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px);
  }
}

@media (min-width: 992px) {
  .card-deck .card {
    flex: 0 0 calc(33.333333% - 30px);
  }
}

@media (min-width: 1200px) {
  .card-deck .card {
    flex: 0 0 calc(33.333333% - 30px);
  }
}

.google-maps {
  width: 98%;
  height: 19rem;
  margin: 0 auto 1rem;
}

.google-maps--large {
  height: 25rem;
}

.island, .island-left {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .island {
    max-width: 33%;
    margin-left: 1em;
    float: right;
  }
  .island-left {
    max-width: 33%;
    margin-right: 1em;
    float: left;
  }
  .island-sm {
    max-width: 25%;
  }
  .island-lg {
    max-width: 50%;
  }
}

/*
* @Author: Jeff Shields
* @Date:   2019-01-29 09:33:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-29 10:08:00
*/
.separator .list-inline-item + .list-inline-item {
  border-left: 1px solid currentColor;
  padding-left: .5rem;
}

/*
* @Author: Jeff Shields
* @Date:   2019-01-29 10:24:25
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-29 10:29:13
*/
.box {
  position: relative;
  border: 0 solid #BBB;
  background: transparent;
}

.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 19px;
  left: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

.ribbon.ribbon-success span {
  background: #28a745;
  background: linear-gradient(#1e7e34 0%, #28a745 100%);
}

.ribbon.ribbon-success span::before {
  border-left: 3px solid #28a745;
  border-top: 3px solid #28a745;
}

.ribbon.ribbon-success span::after {
  border-right: 3px solid #28a745;
  border-top: 3px solid #28a745;
}

.ribbon.ribbon-danger span {
  background: #dc3545;
  background: linear-gradient(#bd2130 0%, #dc3545 100%);
}

.ribbon.ribbon-danger span::before {
  border-left: 3px solid #dc3545;
  border-top: 3px solid #dc3545;
}

.ribbon.ribbon-danger span::after {
  border-right: 3px solid #dc3545;
  border-top: 3px solid #dc3545;
}

@media print {
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }
  h1, .h1 {
    font-size: 19.5pt;
  }
  h2, .h2 {
    font-size: 18pt;
  }
  h3, .h3 {
    font-size: 16.5pt;
  }
  h4, .h4 {
    font-size: 15pt;
  }
  h5, .h5 {
    font-size: 13.5pt;
  }
  h6, .h6 {
    font-size: 12pt;
  }
  .lead {
    font-size: 13.5pt;
  }
  #demoSelect,
  #back-top {
    display: none;
  }
  .container {
    width: auto;
  }
  .card-columns {
    column-count: 2;
  }
}

/*# sourceMappingURL=extras.css.map */