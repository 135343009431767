/*
* @Author: Jeff Shields
* @Date:   2019-01-16 18:44:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-06 09:24:30
*/

.card-columns {

    @include media-breakpoint-up(lg) {
        column-count: $card-columns-count + 1;
    }

    @include media-breakpoint-up(xl) {
        column-count: $card-columns-count + 2;
    }
}


// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3
);

@each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
        .card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
        }
    }
}
